export default {
  namespaced: true,
  state: {
    isAuth: false,
    isHidden: false,
    roleUsers: [2, 3]
  },
  mutations: {
    SET_AUTH(state, value) {
      state.isAuth = value;
    },
    SET_HIDDEN(state, value) {
      state.isHidden = value;
    },
  },
  actions: {
    setAuth({ commit }, value) {
      commit("SET_AUTH", value);
    },
  },
};
