import store from "@/store";

function convertDatename(date) {
    return new Date(date).toLocaleString("ja", { weekday: "short" });
}

function checkUser(roleId) {
    return store.state.auth.roleUsers.includes(roleId);
}

export { convertDatename, checkUser }