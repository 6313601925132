export default {
  namespaced: true,
  state: {
    currentUser: {},
    listUser: [],
  },
  mutations: {
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    SET_LIST_USER(state, payload) {
      state.listUser = payload;
    },
  },
  actions: {
    setCurrentUser({ commit }, user) {
      commit("SET_CURRENT_USER", user);
    },
  },
};
