/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import { getCookie } from '@/utils/cokkie'
import axios from 'axios'

// import { AuthService } from '@/services/auth.service'
// import { API_URL } from '../.env'

export class Http {
  constructor (status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.token = status && status.token ? status.token : null
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_BASE_API_URL
    })

    return this.init()
  }

  init () {
    if (this.isAuth) {
      this.instance.interceptors.request.use(request => {
        if (getCookie('access_token')) {
          request.headers.authorization = 'Bearer ' + getCookie('access_token')
        }
        return request

        // request.headers.authorization = AuthService.getBearer()
        // // if access token expired and refreshToken is exist >> go to API and get new access token
        // if (AuthService.isAccessTokenExpired() && AuthService.hasRefreshToken()) {
        //   return AuthService.debounceRefreshTokens()
        //     .then(response => {
        //       AuthService.setBearer(response.data.accessToken)
        //       request.headers.authorization = AuthService.getBearer()
        //       return request
        //     }).catch(error => Promise.reject(error))
        // } else {
        //   return request
        // }
      }, error => {
        return Promise.reject(error)
      })
    }

    if (this.token) {
      this.instance.interceptors.request.use(request => {
        request.headers.authorization = 'Bearer ' + this.token
        return request
      }, error => {
        return Promise.reject(error)
      }) 
    }

    return this.instance
  }
}
