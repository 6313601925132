export default {
  namespaced: true,
  state: {
    isExpand: localStorage.getItem('isExpandSidebar') !== 'false',
  },
  mutations: {
    SET_IS_EXPAND(state, value) {
      state.isExpand = value;
    },
  },
};
