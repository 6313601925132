<template>
  <div class="loader" id="loader"><div class="loader-inner"></div></div>
</template>
<script>
export default {
  name: "Loader",
};
</script>
<style lang="css" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.3);
}
/* .loader.-hide {
  display: none;
} */
.loader-inner,
.loader-inner:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
}
.loader-inner {
  position: relative;
  top: 50%;
  margin: -4em auto 0;
  border: 1em solid rgba(255, 255, 255, 0.4);
  border-left: 1em solid #fff;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loading 1s infinite linear;
  animation: loading 1s infinite linear;
}
@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
