export const notificationStatus = {
  update: "アップデート",
  important: "重要",
  businesshours: "営業時間",
  etc: "その他",
}

export const initOrganization = {
  name: null,
  status: "DRAFTED",
  status_name: null,
  organization_sections: []
}

export const sortOrganization = {
  title: "name",
  status_name: "status",
  name: "editor_name",
  updated_at: "updated_at",
}

export const sortSurvey = {
  name: "name",
  start_date: "created_at",
  end_date: "deadline",
  average_score: "average_score",
}
