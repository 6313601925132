import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class OrganizationService extends BaseService {
  static get entity () {
    return 'api/organization-charts'
  }
  static params (obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  static async getList (params) {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/paginate?${this.params(params)}`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getDetail (id, status = "DRAFTED") {
    try {
      const response = await this.request({ auth: true }).get(`${this.entity}/${id}?status=${status}`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async delete (id) {
    try {
      const response = await this.request({ auth: true }).delete(`${this.entity}/${id}`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async create (obj) {
    try {
      const response = await this.request({ auth: true }).post(`${this.entity}/`, obj);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async update (id, obj) {
    try {
      const response = await this.request({ auth: true }).put(`${this.entity}/${id}`, obj);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async release (id) {
    try {
      const response = await this.request({ auth: true }).post(`${this.entity}/${id}/release`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async duplicate (id) {
    try {
      const response = await this.request({ auth: true }).post(`${this.entity}/${id}/duplicate`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateStatus (id, statusName) {
    try {
      const response = await this.request({ auth: true }).put(`${this.entity}/${id}/status-name`, {
        status_name: statusName
      });
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
