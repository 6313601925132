import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper, convertParams } from './util'

export class UsersService extends BaseService {
  static get entity () {
    return 'users'
  }

  static async getUsers (params) {
    try {
      const response = await this.request({ auth: true }).get(`api/users/paginate?${convertParams(params)}`);
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async detailUser (id) {
    try {
      const response = await this.request({ auth: true }).get(`api/users/${id}`)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async addUser (formData) {
    try {
      const response = await this.request({ auth: true }).post('api/users', formData)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteUser (data) {
    try {
      const response = await this.request({ auth: true }).delete('api/users/batch-delete', {data})
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  
  static async updatelUser (id, formData) {
    try {
      const response = await this.request({ auth: true }).post(`api/users/${id}`, formData)
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
  

  static async sendMailForgot (email) {
    try {
      const response = await this.request({ auth: false }).post(`api/forgot-password`, {
        email: email
      })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async resetPassword ({ email, password, passwordConfirm, token }) {
    try {
      const response = await this.request({ auth: false }).post(`api/reset-password`, {
        email, password, password_confirmation: passwordConfirm, token
      })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
