const dataTableUsers = {
  headers: [
    { text: "ユーザー名", is_filter: true, value: "name" },
    { text: "所属部門名", is_filter: true, value: "organization_group_name" },
    { text: "役職名", is_filter: true, value: "organization_group_title" },
    { text: "メールアドレス", is_filter: true, value: "email" },
    { text: "メール通知", is_filter: true, value: "accept_email_notification" },
    { text: "就業状況", is_filter: true, value: "employment_status" },
    { text: "権限", is_filter: true, value: "role" },
    { text: "", value: "action" },
    { text: "Delete", value: "delete" },
  ],
  content: [],
  totalPage: 1,
};

const dataTableSurvey = {
  totalPage: 1,
  headers: [
    { text: "サーベイ名", value: "name", is_filter: true },
    { text: "回答期限", value: "end_date", is_filter: true },
    { text: "設定日", value: "start_date", is_filter: true },
    { text: "回答者数 / 対象者数", value: "survey_process_status" },
    { text: "回答期限切れ通知", value: "display" },
    { text: "", value: "action" },
  ],
  content: [],
};

const dataTableSurveyResult = {
  totalPage: 1,
  headers: [
    { text: "サーベイ名", value: "name", is_filter: true },
    { text: "回答期限", value: "deadline", is_filter: true },
    { text: "設定日", value: "created_at", is_filter: true },
    { text: "回答者数 / 対象者数", value: "survey_process_status" },
    { text: "平均点", value: "average_score", is_filter: true },
    { text: "", value: "action" },
  ],
  content: [],
};

const dataListPerson = {
  headers: [
    { text: "ユーザー名", value: "name", is_filter: true },
    { text: "所属部門名", value: "organization_group_name", is_filter: true },
    { text: "役職名", value: "organization_group_title", is_filter: true },
    { text: "回答日時", value: "date_completed", is_filter: true },
    { text: "ステータス", value: "completed", is_filter: true },
    { text: "得点", value: "average", is_filter: true },
    { text: "自己評価意識", value: "SELF_EVALUATION_AWARENESS", is_filter: true },
    { text: "組織内位置認識", value: "ORGANIZATIONAL_POSITION_AWARENESS", is_filter: true },
    { text: "結果明確", value: "CLEAR_RESULTS", is_filter: true },
    { text: "成果視点", value: "RESULTS_ORIENTED", is_filter: true },
    { text: "免責意識", value: "ACCOUNTABILITY_AWARENESS", is_filter: true },
    { text: "変化意識", value: "CHANGE_AWARENESS", is_filter: true },
    { text: "行動優先意識", value: "ACTION_PRIORITY_AWARENESS", is_filter: true },
    { text: "時感覚", value: "SENSE_OF_TIME", is_filter: true },
    { text: null, value: "last_email_sent_at" },
    { text: null, value: "action_delete" },
  ],
  content: []
};

const dataNewSurveyStep2 = {
  totalPage: 1,
  headers: [
    { text: null, value: "check" },
    { text: "ユーザー名", value: "name", is_filter: true },
    { text: "所属部門名", value: "organization_group_name", is_filter: true },
    { text: "役職名", value: "organization_group_title", is_filter: true },
    { text: "通知", value: "action" },
  ],
  content: [],
};

const tableOrganization = {
  totalPage: 1,
  headers: [
    { text: "タイトル", value: "title", is_filter: true },
    { text: "ステータス", value: "status_name", is_filter: true },
    { text: "編集者", value: "name", is_filter: true },
    { text: "更新日", value: "updated_at", is_filter: true }, 
    { text: null, value: "action" },
  ],
  content: []
};

const tableOrganizationUser = {
  totalPage: 1,
  headers: [
    { text: "タイトル", value: "title", is_filter: true },
    { text: "更新日", value: "updated_at", is_filter: true }, 
    { text: null, value: "action" },
  ],
  content: []
}

const tableNotification = {
  totalPage: 1,
  headers: [
    { text: '番号順', value: 'stt'},
    { text: 'タイトル', value: 'title', is_filter: true },
    { text: 'ステータス', value: 'is_public', is_filter: true },
    { text: '状態', value: 'type', is_filter: true },
    { text: null, value: 'action'},
  ],
  content: []
}

const organization = {
  id: 15818,
  status: "released",
  status_name: "公開",
  title: "",
  last_updated_user_name: "グエン・チョン・ザン",
  last_updated_user_name_abbr: "グエ",
  has_image: false,
  last_updated_user_avatar_url:
    "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
  updated_at: "2023/10/17",
  organization_sections: [
    {
      id: 244178,
      organization_chart_id: 15818,
      name: "代表",
      depth: 1,
      groups: [
        {
          id: 1237147,
          organization_section_id: 244178,
          parent_id: 0,
          name: "代表",
          rule: null,
          bundle_uuid: "3dfc54d2-665e-11ee-89d7-c7d333f12cca",
          sort_number: 0,
          has_objective_relation: false,
          users: [
            {
              id: 2157016,
              user_id: 232720,
              group_id: 1237147,
              name: "木村友保",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "木村",
              title: null,
              is_boss: true,
              sort_number: 0,
            },
          ],
        },
      ],
    },
    {
      id: 244179,
      organization_chart_id: 15818,
      name: "部長",
      depth: 2,
      groups: [
        {
          id: 1237148,
          organization_section_id: 244179,
          parent_id: 1237147,
          name: "部長",
          rule: null,
          bundle_uuid: "fdcadbf0-666e-11ee-a7f6-796f331f207f",
          sort_number: 0,
          has_objective_relation: false,
          users: [
            {
              id: 2157017,
              user_id: 235251,
              group_id: 1237148,
              name: "増井良太",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "増井",
              title: "統括マネージャー",
              is_boss: true,
              sort_number: 0,
            },
          ],
        },
        {
          id: 1237149,
          organization_section_id: 244179,
          parent_id: 1237147,
          name: "経理部長",
          rule: null,
          bundle_uuid: "fab59ff2-665e-11ee-89d7-c7d333f12cca",
          sort_number: 1,
          has_objective_relation: false,
          users: [
            {
              id: 2157018,
              user_id: 235250,
              group_id: 1237149,
              name: "木村由梨",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "木村",
              title: "",
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
      ],
    },
    {
      id: 244180,
      organization_chart_id: 15818,
      name: "店長",
      depth: 3,
      groups: [
        {
          id: 1237150,
          organization_section_id: 244180,
          parent_id: 1237148,
          name: "本店",
          rule: null,
          bundle_uuid: "558920f0-666e-11ee-a7f6-796f331f207f",
          sort_number: 0,
          has_objective_relation: false,
          users: [
            {
              id: 2157019,
              user_id: 238687,
              group_id: 1237150,
              name: "日吉亮",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "日吉",
              title: "店長",
              is_boss: true,
              sort_number: 0,
            },
          ],
        },
        {
          id: 1237151,
          organization_section_id: 244180,
          parent_id: 1237148,
          name: "EC販売部",
          rule: null,
          bundle_uuid: "a1543470-666e-11ee-a7f6-796f331f207f",
          sort_number: 3,
          has_objective_relation: false,
          users: [
            {
              id: 2157020,
              user_id: 235250,
              group_id: 1237151,
              name: "木村由梨",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "木村",
              title: "",
              is_boss: true,
              sort_number: 0,
            },
          ],
        },
      ],
    },
    {
      id: 244181,
      organization_chart_id: 15818,
      name: "一般",
      depth: 5,
      groups: [
        {
          id: 1237152,
          organization_section_id: 244181,
          parent_id: 1237150,
          name: "本店",
          rule: null,
          bundle_uuid: "91c54e52-665e-11ee-89d7-c7d333f12cca",
          sort_number: 1,
          has_objective_relation: false,
          users: [
            {
              id: 2157021,
              user_id: 235257,
              group_id: 1237152,
              name: "志村伊武紀",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "志村",
              title: null,
              is_boss: false,
              sort_number: 0,
            },
            {
              id: 2157022,
              user_id: 301334,
              group_id: 1237152,
              name: "渡辺　克哉",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "渡克",
              title: null,
              is_boss: false,
              sort_number: 2,
            },
            {
              id: 2157023,
              user_id: 290486,
              group_id: 1237152,
              name: "ポンセ　レイモンド",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "ポレ",
              title: null,
              is_boss: false,
              sort_number: 1,
            },
          ],
        },
      ],
    },
    {
      id: 244182,
      organization_chart_id: 15818,
      name: "アルバイト",
      depth: 6,
      groups: [
        {
          id: 1237153,
          organization_section_id: 244182,
          parent_id: 1237150,
          name: "本店",
          rule: null,
          bundle_uuid: "9892a391-665e-11ee-89d7-c7d333f12cca",
          sort_number: 2,
          has_objective_relation: false,
          users: [
            {
              id: 2157024,
              user_id: 235262,
              group_id: 1237153,
              name: "田村真奈美",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "田村",
              title: null,
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
        {
          id: 1237154,
          organization_section_id: 244182,
          parent_id: 1237150,
          name: "アイグループ",
          rule: null,
          bundle_uuid: "9892a393-665e-11ee-89d7-c7d333f12cca",
          sort_number: 3,
          has_objective_relation: false,
          users: [],
        },
        {
          id: 1237155,
          organization_section_id: 244182,
          parent_id: 1237148,
          name: "アンティークス",
          rule: null,
          bundle_uuid: "e56e15a3-665e-11ee-89d7-c7d333f12cca",
          sort_number: 1,
          has_objective_relation: false,
          users: [
            {
              id: 2157025,
              user_id: 274786,
              group_id: 1237155,
              name: "藤原 好未",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "藤好",
              title: null,
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
        {
          id: 1237156,
          organization_section_id: 244182,
          parent_id: 1237151,
          name: "EC販売部",
          rule: null,
          bundle_uuid: "453aed03-665f-11ee-966e-4d456a758f21",
          sort_number: 0,
          has_objective_relation: false,
          users: [
            {
              id: 2157026,
              user_id: 239221,
              group_id: 1237156,
              name: "大内直樹",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "大内",
              title: null,
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
      ],
    },
    {
      id: 244183,
      organization_chart_id: 15818,
      name: "主任",
      depth: 4,
      groups: [
        {
          id: 1237157,
          organization_section_id: 244183,
          parent_id: 1237150,
          name: "本店",
          rule: null,
          bundle_uuid: "558920f1-666e-11ee-a7f6-796f331f207f",
          sort_number: 0,
          has_objective_relation: false,
          users: [
            {
              id: 2157027,
              user_id: 235254,
              group_id: 1237157,
              name: "上原類",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "上原",
              title: "副店長",
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
        {
          id: 1237158,
          organization_section_id: 244183,
          parent_id: 1237151,
          name: "EC販売部",
          rule: null,
          bundle_uuid: "a1543471-666e-11ee-a7f6-796f331f207f",
          sort_number: 1,
          has_objective_relation: false,
          users: [
            {
              id: 2157028,
              user_id: 235260,
              group_id: 1237158,
              name: "長谷川理絵",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "長谷",
              title: "主任",
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
        {
          id: 1237159,
          organization_section_id: 244183,
          parent_id: 1237148,
          name: "アンティークス",
          rule: null,
          bundle_uuid: "fdcadbf1-666e-11ee-a7f6-796f331f207f",
          sort_number: 2,
          has_objective_relation: false,
          users: [
            {
              id: 2157029,
              user_id: 235254,
              group_id: 1237159,
              name: "上原類",
              has_image: false,
              avatar_url:
                "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
              name_abbr: "上原",
              title: "副店長",
              is_boss: false,
              sort_number: 0,
            },
          ],
        },
      ],
    },
  ],
}

const tableAssignUsers = {
  totalPage: 1,
  headers: [
    {text: 'ユーザー名', value: 'name', is_filter: true},
    {text: 'ソシキサーベイ', value: 'survey' },
    {text: '新卒用サーベイ', value: 'survey_student'}
  ],
  content: []
}

const organizationBundle = {
  "id": 16375,
  "status": "drafted",
  "status_name": "非公開",
  "title": "",
  "last_updated_user_name": "グエン・チョン・ザン",
  "last_updated_user_name_abbr": "グエ",
  "has_image": false,
  "last_updated_user_avatar_url": "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
  "updated_at": "2023/11/06",
  "organization_sections": [
      {
          "id": 251202,
          "organization_chart_id": 16375,
          "name": "BLEK",
          "depth": 1,
          "groups": [
              {
                  "id": 1269487,
                  "organization_section_id": 251202,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "64247620-7cb5-11ee-9f6c-93abfc4be663",
                  "sort_number": 0,
                  "has_objective_relation": false,
                  "users": [
                      {
                          "id": 2213201,
                          "user_id": 232720,
                          "group_id": 1269487,
                          "name": "木村友保",
                          "has_image": false,
                          "avatar_url": "//d34i7kwacsp1qw.cloudfront.net/assets/user-958851872d7a849ea34d9887d31e41ce820428c6fccef8cb62b595382379b407.png",
                          "name_abbr": "木村",
                          "title": null,
                          "is_boss": true,
                          "sort_number": 0
                      }
                  ]
              },
              {
                  "id": 1270998,
                  "organization_section_id": 251202,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "98b1f120-7cb4-11ee-9f6c-93abfc4be663",
                  "sort_number": 4,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271069,
                  "organization_section_id": 251202,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "4d507f70-7cb5-11ee-9f6c-93abfc4be663",
                  "sort_number": 6,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271070,
                  "organization_section_id": 251202,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "f47e5640-7cb1-11ee-8c41-43cd6aae061f",
                  "sort_number": 8,
                  "has_objective_relation": false,
                  "users": []
              }
          ]
      },
      {
          "id": 251211,
          "organization_chart_id": 16375,
          "name": "",
          "depth": 3,
          "groups": [
              {
                  "id": 1271071,
                  "organization_section_id": 251211,
                  "parent_id": 1271069,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "4d507f71-7cb5-11ee-9f6c-93abfc4be663",
                  "sort_number": 0,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271072,
                  "organization_section_id": 251211,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "4763e3b0-7ca9-11ee-8c41-43cd6aae061f",
                  "sort_number": 2,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271073,
                  "organization_section_id": 251211,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "98b1f121-7cb4-11ee-9f6c-93abfc4be663",
                  "sort_number": 5,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271096,
                  "organization_section_id": 251211,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "f47e5640-7cb1-11ee-8c41-43cd6aae061f",
                  "sort_number": 9,
                  "has_objective_relation": false,
                  "users": []
              }
          ]
      },
      {
          "id": 251601,
          "organization_chart_id": 16375,
          "name": "",
          "depth": 4,
          "groups": [
              {
                  "id": 1271094,
                  "organization_section_id": 251601,
                  "parent_id": 1269487,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "64247621-7cb5-11ee-9f6c-93abfc4be663",
                  "sort_number": 0,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271095,
                  "organization_section_id": 251601,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "4763e3b0-7ca9-11ee-8c41-43cd6aae061f",
                  "sort_number": 3,
                  "has_objective_relation": false,
                  "users": []
              },
              {
                  "id": 1271097,
                  "organization_section_id": 251601,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "ea68dae0-7cb1-11ee-8c41-43cd6aae061f",
                  "sort_number": 7,
                  "has_objective_relation": false,
                  "users": []
              }
          ]
      },
      {
          "id": 251602,
          "organization_chart_id": 16375,
          "name": "",
          "depth": 5,
          "groups": [
              {
                  "id": 1271098,
                  "organization_section_id": 251602,
                  "parent_id": 1271094,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "e4f78bd1-7cb4-11ee-9f6c-93abfc4be663",
                  "sort_number": 0,
                  "has_objective_relation": false,
                  "users": []
              }
          ]
      },
      {
          "id": 251936,
          "organization_chart_id": 16375,
          "name": "",
          "depth": 2,
          "groups": [
              {
                  "id": 1272823,
                  "organization_section_id": 251936,
                  "parent_id": 0,
                  "name": "",
                  "rule": null,
                  "bundle_uuid": "4763e3b0-7ca9-11ee-8c41-43cd6aae061f",
                  "sort_number": 1,
                  "has_objective_relation": false,
                  "users": []
              }
          ]
      }
  ]
}

export {
  dataTableUsers,
  dataTableSurvey,
  dataTableSurveyResult,
  dataListPerson,
  dataNewSurveyStep2,
  tableOrganization,
  tableNotification,
  organization,
  organizationBundle,
  tableAssignUsers,
  tableOrganizationUser
};

