<template>
  <div id="app">
    <router-view></router-view>
    <loader v-if="loading" />
    <FlashMessage :position="'right top'" />
  </div>
</template>

<script>
import Loader from "./components/Loader.vue";
export default {
  name: "App",
  components: {
    Loader,
  },
  computed: {
    loading() {
      return this.$store.state.loader.isShow;
    },
  },
};
</script>

<style></style>
