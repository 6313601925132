import Vue from "vue";
import Vuex from "vuex";
import loader from "./loader";
import auth from "./auth";
import user from "./user";
import organization from "./organization";
import sidebar from "./sidebar";
import survey from "./survey";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    loader,
    auth,
    user,
    organization,
    survey,
    sidebar
  },
});
