import Vue from "vue";
import VueRouter from "vue-router";
import $store from "@/store";
import { AuthService } from "./services/auth.service";
import { getCookie, eraseCookie } from "./utils/cokkie";

Vue.use(VueRouter);

function loadView(view, rootPath = "") {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/pages/${rootPath}${view}.vue`
    );
}

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { checkAuth: true },
    component: loadView("login"),
  },
  {
    path: "/new_password",
    component: loadView("new-password"),
  },
  {
    path: "/reset-password",
    component: loadView("reset-password"),
  },
  {
    path: "/",
    name: "root",
    component: () => import(`@/layout/default.vue`),
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    children: [
      {
        path: "dashboard",
        component: loadView("dashboard"),
        props: true,
        name: "dashboard",
      },
      {
        path: "advertisements",
        component: loadView("advertisements/list"),
        props: true,
        name: "advertisements",
      },
      {
        path: "advertisements/:id",
        component: loadView("advertisements/item"),
        props: true,
        name: "advertisement-id",
      },
      {
        path: "organization_charts",
        component: loadView("organization_charts", "organization/"),
        meta: { name: "organization", },
        props: true,
      },
      {
        path: "organization_charts/:id",
        component: loadView("detail", "organization/"),
        meta: { name: "organization", isFixed: true },
        props: true,
      },
      {
        path: "organization_charts/:id/edit",
        component: loadView("edit", "organization/"),
        meta: { name: "organization", isFixed: true, requiresAdmin: true },
        props: true,
      },
      {
        path: "survey/top",
        component: loadView("index", "surveys/"),
        meta: { name: "survey-employee", isChangeBackground: true, requiresSoshiki: true },
        props: true,
      },
      {
        path: "survey",
        component: loadView("survey", "surveys/"),
        meta: { name: "survey-employee", subName: "survey-employee-list" },
        props: true,
      },
      {
        path: "survey/show_result",
        component: loadView("result", "surveys/"),
        meta: { name: "survey-employee", subName: "survey-employee-result", requiresAdmin: true },
        props: true,
      },
      {
        path: "survey/show_result/:id",
        component: loadView("survey-point", "surveys/"),
        meta: { name: "survey-employee", requiresAdmin: true},
        props: true,
      },
      {
        path: "survey/new",
        component: loadView("new-survey", "surveys/"),
        name: "new-survey",
        meta: { name: "survey-employee", requiresAdmin: true},
        props: true,
      },
      {
        path: "/survey/user_goals/",
        component: loadView("user-goal", "surveys/"),
        meta: { name: "survey-employee", isChangeBackground: true, subName: "survey-employee-goal", requiresSoshiki: true },
      },
      {
        path: "/survey/exams/:id",
        component: loadView("survey-exam", "surveys/"),
        meta: { name: "survey-employee" },
      },
      {
        path: "/survey/exams/:id/:token",
        component: loadView("survey-exam", "surveys/"),
        meta: { name: "survey-employee", checkToken: true },
      },
      {
        path: "survey/:id",
        component: loadView("detail", "surveys/"),
        name: 'detail-survey',
        meta: { name: "survey-employee", requiresAdmin: true},
        props: true,
      },
      {
        path: "survey/clone/:id",
        component: loadView("detail", "surveys/"),
        meta: {requiresAdmin: true},
        name: 'clone-survey',
        props: true,
      },
      {
        path: "survey",
        component: loadView("survey"),
        meta: { name: "survey-employee", },
        props: true,
      },
      {
        path: "settings",
        component: loadView("index", "settings/"),
        meta: { name: "settings", isChangeBackground: true, requiresAdmin: true },
        name: "settings",
        props: true,
      },
      {
        path: "settings/users",
        component: loadView("users", "settings/"),
        meta: { name: "settings", requiresAdmin: true },
        name: 'list-users',
        props: true,
      },
      {
        path: "settings/users/new",
        component: loadView("new-user", "settings/"),
        meta: { name: "settings", requiresAdmin: true },
        props: true,
      },
      {
        path: "settings/users/:id",
        component: loadView("edit-user", "settings/"),
        meta: { name: "settings", requiresAdmin: true },
        props: true,
      },
      {
        path: "users/edit",
        component: loadView("profile", "profile/"),
        props: true,
      },
      {
        path: "settings/assign",
        component: loadView("assign-user", "settings/"),
        meta: { name: "settings", requiresAdmin: true},
        name: 'assign-user',
        props: true,
      },
      {
        path: "survey-student/top",
        component: loadView("index", "surveys/"),
        meta: { isChangeBackground: true, name: "survey-student", requiresGraduates: true },
        props: true,
        
      },
      {
        path: "survey-student",
        component: loadView("survey", "surveys/"),
        meta: { name: "survey-student", subName: "survey-student-list" },
        props: true,
      },
      {
        path: "/survey-student/show_result",
        component: loadView("result", "surveys/"),
        meta: { name: "survey-student", subName: "survey-student-result" , requiresAdmin: true},
        props: true,
      },
      {
        path: "/survey-student/show_result/:id",
        component: loadView("survey-point", "surveys/"),
        meta: { name: "survey-student", requiresAdmin: true},
        props: true,
      },
      {
        path: "/survey-student/new",
        component: loadView("new-survey", "surveys/"),
        meta: { name: "survey-student", requiresAdmin: true},
        props: true,
      },
      {
        path: "/survey-student/user_goals",
        component: loadView("user-goal", "surveys/"),
        meta: { name: "survey-student", isChangeBackground: true, subName: "survey-student-goal", requiresGraduates: true },
      },
      {
        path: "/survey-student/:id",
        component: loadView("detail", "surveys/"),
        meta: {name: 'survey-student', requiresAdmin: true},
        name: 'detail-survey-student',
        props: true,
      },
      {
        path: "/survey-student/clone/:id",
        component: loadView("detail", "surveys/"),
        meta: {name: 'survey-student', requiresAdmin: true},
        name: 'clone-student',
        props: true,
      },
      {
        path: "/survey-student/exams/:id",
        component: loadView("survey-exam", "surveys/"),
        meta: { name: "survey-student" },
      },
      {
        path: "/survey-student/exams/:id/:token",
        component: loadView("survey-exam", "surveys/"),
        meta: { name: "survey-student", checkToken: true },
      },
      {
        path: "notifications",
        component: loadView("list", "notification/"),
        meta: { name: "notification", requiresAdmin: true},
        props: true,
      },
      {
        path: "notifications/create",
        component: loadView("create", "notification/"),
        meta: { name: "create-notification", requiresAdmin: true},
        props: true,
      },
      {
        path: "notifications/:id",
        component: loadView("detail", "notification/"),
        meta: { name: "create-notification", requiresAdmin: true},
        props: true,
      },
    ],
  },
  {
    path: "*",
    component: loadView("404"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, _, next) => {
  try {
    if (to.matched.some((routeRecord) => routeRecord.meta.requiresAuth)) {
      if (!$store.state.auth.isAuth) {
        // Check Token Survey Exams
        if (to.meta.checkToken) {
          handleWithToken(to, _, next);
        } else {
          const res = await AuthService.getCurrent();
          if (to.meta.requiresAdmin) {
            if (res.data.role_id === 1) return next();
            return next('/');
          } else if (to.meta.requiresSoshiki) {
            if (res.data.role_id === 1) return next();
            if (res.data.permissions.some(item => item.id == 1)) return next();
            return next('/');
          } else if (to.meta.requiresGraduates) {
            if (res.data.role_id === 1) return next();
            if (res.data.permissions.some(item => item.id == 2)) return next();
            return next('/');
          } else {
            next();
          }
        }
      } else {
        next();
      }
    } else if (
      to.matched.some((routeRecord) => routeRecord.meta.checkAuth) &&
      getCookie("access_token")
    ) {
      next("/");
    } else {
      next();
    }
  } catch (error) {
    next("/login");
  }
});

async function handleWithToken(to, _, next) {
  const token = to.params.token;
  if (token) {
    try {
      const res = await AuthService.getCurrentWithToken(token);
      $store.dispatch('user/setCurrentUser', res.data)
      try {
        const dataUser = await AuthService.getCurrentWithToken(getCookie("access_token"))
        if (dataUser.data.id == res.data.id) {
          // Hande same user
          next();
        } else {
          // Handle diff user
          eraseCookie("access_token")
          $store.commit('auth/SET_HIDDEN', true)
          next();
        }
      } catch (error) {
        $store.commit('auth/SET_HIDDEN', true)
        next();
      }
    } catch (error) {
      eraseCookie("access_token")
      next('/login')
    }
  }
}

export default router;
