import { organization, organizationBundle } from "@/support/data";
import { initOrganization } from "@/constants";
import { OrganizationService } from "@/services/organization.service";

export default {
  namespaced: true,
  state: {
    organization: initOrganization,
    nodesHeight: [],
    isShowModalConnect: false,
    groupConnect: {},
  },
  mutations: {
    SET_ORGANIZATION_TITLE(state, payload) {
      state.organization.name = payload;
    },
    SET_ORGANIZATION(state, payload) {
      state.organization = payload ? payload: initOrganization;
    },
    SET_NODES_HEIGHT(state, payload) {
      state.nodesHeight = payload;
    },
    SET_IS_SHOW_MODAL_CONNECT(state, payload) {
      state.isShowModalConnect = payload;
    },
    SET_GROUP_CONNECT(state, payload) {
      state.groupConnect = payload;
    },
  },
  actions: {
    setOrganization({ commit }, payload) {
      commit("SET_ORGANIZATION", payload);
    },
    setNodesHeight({ commit }, payload) {
      commit("SET_NODES_HEIGHT", payload);
    },
    updateOrganization({ commit, dispatch }, payload) {
      return new Promise(async (resolve) => {
        try {
          dispatch("loader/toggleLoader", null, { root: true });
          const res = await OrganizationService.update(payload.id, payload)
          commit("SET_ORGANIZATION", res.data);
        } catch (error) {
          console.log(error)
        }
        dispatch("loader/toggleLoader", null, { root: true });
        resolve();
      })
    }
  },
};
