import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// Font anwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret, faEye, faEyeSlash, faHouse, 
  faNetworkWired, faChartLine, faChartGantt, faGear, faListCheck, faList, faUserGear, faUsersGear, faPeopleArrows } from "@fortawesome/free-solid-svg-icons";
import vClickOutside from "v-click-outside";
import store from "./store";
// Vuetify
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@/main.css";
import FlashMessage from "@smartweb/vue-flash-message";
import VueMoment from "vue-moment";
import { faPenToSquare, faBell } from "@fortawesome/free-regular-svg-icons";
import { checkUser } from "./support/helper";


Vue.use(VueMoment)

Vue.use(FlashMessage, {
  time: 1500,
});

Vue.use(vClickOutside);
Vue.use(Vuetify);

const vuetify = new Vuetify();
/* add icons to the library */
library.add(faUserSecret, faEye, faEyeSlash, faHouse, 
  faNetworkWired, faChartLine, faChartGantt, faGear, faListCheck, 
  faPenToSquare, faList, faListCheck, faUserGear, faUsersGear, faPeopleArrows, faBell);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.prototype.$checkUser = checkUser;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
