import { UsersService } from "@/services/users.service"

export default {
    namespaced: true,
    state: {
      dataUser: {},
      listAccountAdmin: [],
    },
    mutations: {
      SET_Data_USER(state, payload) {
        state.dataUser = payload;
      },
      SET_LIST_ADMIN(state, payload) {
        state.listAccountAdmin = payload;
      }
    },
    actions: {
      setDataUser({ commit }, data) {
        commit("SET_Data_USER", data);
      },
      async getListAdmin( {commit}, type) {
        try {
          let newData = [];
          const res = await UsersService.getUsers({role_id: 1, pageSize: 100, permission_id: type === 'survey-student' ? 2 : 1});
          for (const admin of res.data.data) {
            const { id, name, avatar } = admin;
            let object = {
              check: id,
              name, 
              avatar
            };
            newData.push(object);
          };
          commit('SET_LIST_ADMIN', newData);
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
  